(function($) {
  Drupal.behaviors.faqTemplate = {
    attached: false,
    attach: function(context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-faq-template', context);
      var $trigger = $('.js-faq-toggle', $template);

      // Check if this has a trigger
      if ($trigger.length > 0) {
        // Toggle the nav
        $trigger.once().on('click', function() {
          var $this = $(this);
          var $parent = $this.parent();
          var $isFormatter = $this.hasClass('faq-formatter__toggle');
          var $content = $isFormatter ? $parent.next('.js-faq-content') : $this.next('.js-faq-content');
          // Cast the expanded state as a boolean
          var expanded = $parent.attr('aria-expanded') !== 'false';

          // Switch the states of aria-expanded and aria-hidden
          $parent.attr('aria-expanded', !expanded);
          $content.attr('aria-hidden', expanded);
        });
      }
    }
  };
})(jQuery);
